import { onMounted, onBeforeUnmount, ref, reactive } from 'vue';
import WangEditor from 'wangeditor';
export default {
  props: ['editorContent'],
  // 接收父组件的方法
  emits: ['catchData'],

  setup(props, context) {
    const editor = ref();
    const content = reactive({
      html: '',
      text: ''
    });
    let instance;
    onMounted(() => {
      instance = new WangEditor(editor.value);
      Object.assign(instance.config, {
        onchange() {
          // console.log('change');
          // console.log(instance.txt.html())
          context.emit('catchData', instance.txt.html()); // 把这个html通过catchData的方法传入父组件
        }

      });
      instance.config.uploadImgServer = '/upload-img'; //图片地址

      instance.create();
      instance.txt.html(props.editorContent); //接受父组件内容
    });
    onBeforeUnmount(() => {
      instance.destroy();
      instance = null;
    });
    return {
      editor
    };
  }

};