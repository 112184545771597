/*
 * 资料管理接口列表
 */

import axios from '@/utils/http'; // 导入http中创建的axios实例
import instance from '@/utils/http';

/**
 * 查询标签
 */
export const selectLabelByParam = (param) => {
  return axios.post(`/personnelapi/HyLabel/selectLabelByParam`, param);
}

//查询资料数据
export const selectHyFileByPagination = (param) => {
  return axios.post(`/personnelapi/HyFile/selectHyFileByPagination`, param);
}

//新增标签
export const insertLabel = (param) => {
  return axios.post(`/personnelapi/HyLabel/insertLabel`, param);
}

//删除标签
export const deleteLabelByParam = (param) => {
  return axios.post(`/personnelapi/HyLabel/deleteLabelByParam`, param);
}

//修改资料数据
export const updateHyFile = (param) => {
  return axios.post(`/personnelapi/HyFile/updateHyFile`, param);
}
//新增日志
export const insertHyFile = (param) => {
  return axios.post(`/personnelapi/HyFile/insertHyFile`, param);
}
//查看日志
export const selectHyFileByFileId = (param) => {
  return axios.post(`/personnelapi/HyFile/selectHyFileByFileId`, param);
}

//删除日志
export const deleteHyFile = (param) => {
  return axios.post(`/personnelapi/HyFile/deleteHyFile`, param);
}
//导出日志
export const writeWorkLog = (param) => {
  return axios.post(`/personnelapi/HyFile/writeWorkLog`, param);
}