import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch } from 'vue';
import editor from '@/components/Editor.vue';
import labelManagement from './components/labelManagement.vue';
import { selectHyFileByPagination, selectLabelByParam, updateHyFile, insertHyFile, selectHyFileByFileId, deleteHyFile, writeWorkLog } from '@/api/data';
import useTablePagination from '@/hooks/pagination';
import uploadImgs from './components/uploadImgs.vue';
import { QuestionCircleOutlined, PaperClipOutlined } from '@ant-design/icons-vue';
import getFieldRules from '@/utils/fieldRules';
import { useRoute } from "vue-router";
import { useStore } from 'vuex';
import Cookies from "js-cookie";
import { selectJobCascade } from '@/api/material';
import { el } from 'element-plus/es/locale'; //表头

const columns = [{
  title: '名称',
  dataIndex: 'logName'
}, {
  title: '标签',
  dataIndex: 'labels',
  slots: {
    customRender: 'labels'
  }
}, {
  title: '物理位置',
  dataIndex: 'physicsLocation'
}, {
  title: '记录人',
  dataIndex: 'logUser'
}, {
  title: '记录时间',
  dataIndex: 'createTime'
}, {
  title: '到期时间',
  dataIndex: 'endDate'
}, {
  title: '状态',
  dataIndex: 'state',
  slots: {
    customRender: 'state'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
const columns1 = [{
  title: '操作人',
  dataIndex: 'userNo'
}, {
  title: '操作行为',
  dataIndex: 'comment'
}, {
  title: '操作时间',
  dataIndex: 'createTime'
}];
const infoObj = {
  ConstructioLog: 1,
  SecurityLog: 2,
  OtherInformation: 3,
  meetingSummary: 4,
  workStatement: 5,
  contractingContract: 15,
  designPaper: 16,
  equipmentMaterial: 17,
  progressPlan: 13,
  inspectionAcceptance: 14,
  projectData: 11,
  publicData: 12,
  companyCertificate: 6,
  companyContract: 7,
  companyProfile: 8,
  staffRecord: 9,
  customerData: 10
};
export default defineComponent({
  components: {
    editor,
    labelManagement,
    uploadImgs,
    QuestionCircleOutlined,
    PaperClipOutlined
  },

  setup() {
    const route = useRoute();
    const store = useStore();
    const menus = computed(() => {
      const arr = store.state.user.menus.find(item => {
        return item.menuName === "data-management";
      });
      return arr.childMenuList;
    }); // console.log(1111111,menus.value)

    onMounted(() => {
      selectLabelByParam1();
      getTableData();
      getJobCascade();
    }); // const findName = (val) => {
    //   let arr = menus.value.find(item => {
    //     return item.menuName === val
    //   })
    //   return arr.menuTitle
    // }
    // const setMaterialParameters = (newParams) => {
    //   console.log(route)
    //   Object.assign(materialParameters,{
    //     logType: infoObj[newParams],
    //     headerTitle: findName(newParams),
    //     nameLabel : `${findName(newParams)}名称`,
    //     detailLabel : `${findName(newParams)}详情`,
    //     imgLabel : `${findName(newParams)}照片`,
    //     addHeaderTitle : `新增${findName(newParams)}`,
    //   })
    //   // switch(newParams) {
    //   //     case 'ConstructioLog':
    //   //       Object.assign(materialParameters,{
    //   //         logType: 1, //1施工日志  2安全日志  3其他资料
    //   //         headerTitle: findName(newParams),
    //   //         nameLabel : `${findName(newParams)}名称`,
    //   //         detailLabel : `${findName(newParams)}详情`,
    //   //         imgLabel : `${findName(newParams)}照片`,
    //   //         addHeaderTitle : `新增${findName(newParams)}`,
    //   //       })
    //   //       break;
    //   //     case 'SecurityLog':
    //   //       Object.assign(materialParameters,{
    //   //         logType: 2, //1施工日志  2安全日志  3其他资料
    //   //         headerTitle: findName(newParams),
    //   //         nameLabel : `${findName(newParams)}名称`,
    //   //         detailLabel : `${findName(newParams)}详情`,
    //   //         imgLabel : `${findName(newParams)}照片`,
    //   //         addHeaderTitle : `新增${findName(newParams)}`,
    //   //       })
    //   //       break;
    //   //     case 'OtherInformation':
    //   //       Object.assign(materialParameters,{
    //   //         logType: 3, //1施工日志  2安全日志  3其他资料
    //   //         headerTitle: findName(newParams),
    //   //         nameLabel : `${findName(newParams)}名称`,
    //   //         detailLabel : `${findName(newParams)}详情`,
    //   //         imgLabel : `${findName(newParams)}照片`,
    //   //         addHeaderTitle : `新增${findName(newParams)}`,
    //   //       })
    //   //       break;
    //   //   }
    // }
    // const materialParameters = reactive({
    //   logType: 1, //1施工日志  2安全日志  3其他资料
    //   headerTitle: '施工日志',
    //   nameLabel : '日志名称',
    //   detailLabel : '日志详情',
    //   imgLabel : '日志照片',
    //   addHeaderTitle : '新增日志',
    // })

    const routerName = ref(route.name);
    const isCompanyAdmin = computed(() => store.state.user.isCompanyAdmin);
    const labelName = computed(() => {
      let arr = menus.value.find(item => {
        return item.menuName === route.name;
      });
      return arr.menuTitle;
    });
    watch(() => route.name, newParams => {
      if (infoObj.hasOwnProperty(newParams)) {
        routerName.value = newParams;
        getTableData();
        selectLabelByParam1();
      }
    });
    const formRef = ref();
    const formRef1 = ref();
    const visible = ref(false);
    const labelVisible = ref(false);
    const formState = reactive({
      data: [],
      label: [],
      logName: '',
      logUser: '',
      physicsLocation: ''
    });
    const tableData = ref([]);
    const loading = ref(false); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //获取表格数据

    const getTableData = async () => {
      const {
        data,
        label,
        logName,
        logUser,
        physicsLocation
      } = formState;
      const [startTime, endTime] = data.map((item, index) => {
        if (index) {
          return item.endOf('day').format('YYYY-MM-DD HH:mm:ss');
        }

        return item.startOf('day').format('YYYY-MM-DD HH:mm:ss');
      });
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        logType: infoObj[route.name],
        startTime: startTime ? startTime : null,
        endTime: endTime ? endTime : null,
        labelIds: label.length ? label : null,
        logName: logName ? logName : null,
        logUser: logUser ? logUser : null,
        physicsLocation: physicsLocation ? physicsLocation : null
      };
      loading.value = true;

      try {
        const res = await selectHyFileByPagination(param);

        if (res.code === 200) {
          const arr = [];
          tableData.value = [];
          if (res.data.list) res.data.list.forEach(item => {
            arr.push({ ...item,
              key: item.id
            });
          });
          Object.assign(tableData.value, arr);
          total.value = res.data.countSize;
          current.value = res.data.nowPage;
          pageSize.value = res.data.pageSize;
          loading.value = false;
        } else {
          loading.value = false;

          _message.error(res.message);
        }
      } catch {
        loading.value = false;
      }
    }; //点击查询


    const onSubmit = () => {
      current.value = 1;
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      formRef.value.resetFields();
      current.value = 1;
      getTableData();
    };

    const state = reactive({
      selectedRowKeys: [],
      // Check here to configure the default column
      loading: false
    });
    const hasSelected = computed(() => state.selectedRowKeys.length > 0); //批量删除

    const batchDelete = () => {
      console.log(state.selectedRowKeys);
    };

    const onSelectChange = selectedRowKeys => {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      state.selectedRowKeys = selectedRowKeys;
    }; //查看详情接口


    const getLogInfo = async id => {
      const res = await selectHyFileByFileId({
        logType: infoObj[route.name],
        id
      });

      if (res.code === 200) {
        const arr = [];

        if (res.data.labels.length) {
          res.data.labels.forEach(item => {
            arr.push(item.id);
          });
        }

        const jlArr = [];

        if (res.data.hyFileLogDtos.length) {
          res.data.hyFileLogDtos.forEach((item, index) => {
            jlArr.push({ ...item,
              key: index
            });
          });
        } // console.log(jlArr)


        Object.assign(formState1, {
          logName: res.data.logName,
          // logUser: res.data.logUser,
          physicsLocation: res.data.physicsLocation,
          logType: infoObj[route.name],
          labelIds: arr,
          creatTime: res.data.creatTime,
          logText: res.data.logText,
          logPics: res.data.logPics !== '' ? JSON.parse(res.data.logPics) : [],
          fileUrl: res.data.fileUrl !== '' ? JSON.parse(res.data.fileUrl) : [],
          hyFileLogDtos: jlArr,
          id: res.data.id,
          endDate: res.data.endDate,
          warnDay: res.data.warnDay,
          logUid: getUid(res.data.logUid)
        }); // console.log(getUid(res.data.logUid))
      } else {
        _message.error(res.message);
      }
    };

    const getUid = val => {
      let arr = [];

      if (useReturnOptions.value.length) {
        useReturnOptions.value.forEach(item => {
          if (item.children.length) {
            item.children.some(ixt => {
              if (ixt.value == val) {
                arr = [ixt.departmentId, val];
                return;
              }
            });
          }
        });
      }

      return arr;
    }; //查看


    const toView = key => {
      console.log(key);
      visible.value = true;
      title.value = `${labelName.value}详情`;
      modalNum.value = 0;
      getLogInfo(key);
    };

    const deleteLog = async id => {
      const res = await deleteHyFile({
        id
      });

      if (res.code === 200) {
        _message.success(res.message);

        getTableData();
      } else {
        _message.error(res.message);
      }
    }; //删除


    const onDelete = key => {
      deleteLog(key);
    }; ///弹窗相关


    const title = ref('新增');
    const modalNum = ref(0); //0-查看，1-新增，2-修改

    const formState1 = reactive({
      logName: '',
      // logUser:"",
      logType: infoObj[route.name],
      labelIds: [],
      // creatTime: '',
      logText: '',
      logPics: [],
      fileUrl: [],
      hyFileLogDtos: [],
      physicsLocation: "",
      endDate: null,
      warnDay: null,
      logUid: []
    });

    const addLog = () => {
      visible.value = true;
      modalNum.value = 1;
      title.value = `新增${labelName.value}`;
      formState1.logUid = [];
    };

    const handleCancel = () => {
      visible.value = false;
      formRef1.value.resetFields();
    };

    const handleOk = () => {
      formRef1.value.validate().then(() => {
        if (formState1.endDate && formState1.warnDay == null) {
          _message.error('到期前提醒时间不能为空！');

          return;
        }

        if (modalNum.value === 1) {
          addLogInterface();
        } else {
          updateInfo();
        }
      }).catch(error => {
        console.log('error', error);
      });
    }; //字段rules


    const {
      checkName,
      checkJob
    } = getFieldRules();
    const rules = {
      logName: [{
        required: true,
        message: `请输入${labelName.value}名称`,
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      // logUser: [
      //   {
      //     required: true,
      //     validator: checkName,
      //     trigger: 'blur',
      //   },
      // ],
      labelIds: [{
        required: true,
        validator: checkJob,
        trigger: 'change'
      }],
      logText: [{
        required: true,
        message: `请输入${labelName.value}详情`,
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      logUid: [{
        required: true,
        message: '请选择记录人',
        type: 'array',
        trigger: 'change'
      }] // physicsLocation: [
      //   {
      //     required: true,
      //     message: '请输入物理位置',
      //     trigger: 'blur',
      //   },
      //   {
      //     pattern: /^[^\s]*$/,
      //     message: '禁止输入空格',
      //     trigger: 'blur',
      //   }
      // ],

    }; //修改

    const toModification = key => {
      console.log(key);
      visible.value = true;
      modalNum.value = 2;
      title.value = `修改${labelName.value}`;
      getLogInfo(key);
    }; //修改资料接口


    const updateInfo = async () => {
      const param = {};
      Object.assign(param, formState1);
      param.logPics = JSON.stringify(param.logPics);
      param.fileUrl = JSON.stringify(param.fileUrl);
      param.hyFileLogDtos = undefined;
      param.logType = infoObj[route.name];
      param.logUid = param.logUid[1];
      const arr = [];

      if (param.labelIds.length) {
        param.labelIds.forEach(item => {
          arr.push({
            id: item
          });
        });
      }

      param.labelIds = arr; // console.log(param)

      const res = await updateHyFile(param);

      if (res.code === 200) {
        _message.success(res.message);

        visible.value = false;
        formRef1.value.resetFields();
        getTableData();
      } else {
        _message.error(res.message);
      }
    }; // 
    /////
    //富文本框
    //接受Editor子组件传过来的值


    const catchData = val => {
      console.log(11112222, val);
      this.formState1.catchData = val;
    }; //标签管理
    //打开标签管理弹窗


    const handleLable = () => {
      labelVisible.value = true;
    };

    const labelNameData = ref([]); //获取标签

    const selectLabelByParam1 = async () => {
      labelNameData.value = [];
      let res = await selectLabelByParam({
        labelName: '',
        logType: infoObj[route.name]
      });

      if (res.code === 200) {
        let arr = [];
        res.data.forEach(item => {
          arr.push({
            id: item.id,
            label: item.labelName
          });
        });
        Object.assign(labelNameData.value, arr);
      } else {
        _message.error(res.message);
      }
    };

    const getLabelData = val => {
      labelNameData.value = [];
      Object.assign(labelNameData.value, val);
    }; //获取图片的url


    const getImgUrls = val => {
      console.log('getImgUrls', val);
      formState1.logPics = val;
    }; //获取附件的url


    const getAdjuncts = val => {
      console.log('getAdjuncts', val);
      formState1.fileUrl = val;
    }; //新增日志接口


    const addLogInterface = async () => {
      const param = {};
      Object.assign(param, formState1);
      param.labelIds = JSON.stringify(param.labelIds);
      param.logPics = JSON.stringify(param.logPics);
      param.fileUrl = JSON.stringify(param.fileUrl);
      param.hyFileLogDtos = undefined;
      param.logType = infoObj[route.name];
      param.logUid = param.logUid[1];
      const res = await insertHyFile(param); // console.log(res)

      if (res.code === 200) {
        _message.success(res.message);

        visible.value = false;
        formRef1.value.resetFields();
        getTableData();
      } else {
        _message.error(res.message);
      }
    }; //点击分页


    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    }; //导出


    const writeVisible = ref(false);

    const writeLog = () => {
      writeVisible.value = true; // title.value = materialParameters.headerTitle
    };

    const writeLogTime = ref([]); // const writeLogLoading = ref(false)

    const handleWriteLog = async () => {
      if (!writeLogTime.value.length) {
        _message.error('请选择开始时间和结束时间');

        return;
      }

      const belongProject = Number(Cookies.get("belongProject"));
      let url = `/wlapi/HyFile/writeWorkLog?belongProject=${belongProject}&startTime=${writeLogTime.value[0]}&endTime=${writeLogTime.value[1]}&logType=${infoObj[route.name]}`;
      let down = document.createElement('a');
      down.href = url;
      down.download = `${labelName.value}导出`;
      down.click();
      down.remove();
      writeVisible.value = false;
    };

    const logType = computed(() => {
      return infoObj[route.name];
    }); // const handleLing = (val) => {
    //   formState1.logUid = val?val[1] : null
    // }
    //岗位及人员

    const useReturnOptions = ref([]);

    const getJobCascade = async () => {
      const belongProject = Number(Cookies.get("belongProject"));
      let res = await selectJobCascade({
        belongProject
      });

      if (res.code === 200) {
        // console.log(res)
        const jobProjectsAll = res.data.jobProjectsAll;
        useReturnOptions.value = conversion(jobProjectsAll);
      }
    };

    const conversion = val => {
      const arr = [];
      let myMap = {};
      val.forEach(item => {
        // let obj = {
        //   value: item.departmentId,
        //   label: item.departmentName,
        //   children: []
        // }
        if (item.hyEmployees.length) {
          item.hyEmployees.forEach(ixt => {
            let obj = {
              value: ixt.uid,
              label: ixt.userName,
              departmentId: ixt.departmentId
            };

            if (!myMap[ixt.departmentId]) {
              let obj1 = {
                value: ixt.departmentId,
                label: ixt.departmentName,
                children: []
              };
              obj1.children.push(obj);
              arr.push(obj1);
              myMap[ixt.departmentId] = ixt.departmentId;
            } else {
              // console.log('arr',arr)
              arr.forEach(ixts => {
                if (ixts.value === ixt.departmentId) {
                  ixts.children.push(obj);
                }
              });
            } // arr.push({...ixt})

          });
        } // arr.push(obj)

      }); // console.log(arr)

      return arr;
    };

    return {
      formRef,
      visible,
      formState,
      tableData,
      columns,
      hasSelected,
      ...toRefs(state),
      // func
      onSubmit,
      resetForm,
      onSelectChange,
      batchDelete,
      toView,
      toModification,
      onDelete,
      paginationConfig,
      //弹窗
      title,
      modalNum,
      addLog,
      handleCancel,
      handleOk,
      formRef1,
      rules,
      columns1,
      ////
      formState1,
      editor,
      catchData,
      ///标签管理
      labelManagement,
      labelVisible,
      handleLable,
      loading,
      labelNameData,
      getLabelData,
      getImgUrls,
      getAdjuncts,
      onPageChange,
      // ...toRefs(materialParameters),
      routerName,
      isCompanyAdmin,
      //导出
      writeLog,
      writeVisible,
      writeLogTime,
      handleWriteLog,
      labelName,
      logType,
      // handleLing,
      useReturnOptions
    };
  }

});